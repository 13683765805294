import Link from '@components/Link';
import React from 'react';
import { CgChevronRight } from 'react-icons/cg';

import Text from '../Text/Text';
import Title from '../Title/Title';
import './Tile.scss';

interface Props {
    title: string;
    subtitle?: string;
    link?: string;
    onClick?: () => void;
    startSlot?: any;
    endSlot?: any;
    variant?: 'button' | 'radio' | 'checkbox';
    size?: 's' | 'm' | 'l';
    radius?: 's' | 'm' | 'l';
    elevation?: 's' | 'm';
}

const Tile: React.FC<Props> = ({
    title,
    subtitle,
    onClick,
    link,
    startSlot,
    endSlot,
    elevation,
    radius = 'm',
    size = 'm',
    variant = 'button',
}) => {
    const className = [
        'Tile',
        size !== undefined ? 'size-' + size : '',
        elevation !== undefined ? 'elevation-' + elevation : '',
        radius !== undefined ? 'radius-' + radius : '',
    ]
        .join(' ')
        .replace(/\s{2,}/g, ' ');

    function renderInSlot(content: any) {
        return <span className="Tile--slot">{content}</span>;
    }

    function renderEndSlot() {
        if (endSlot) {
            return renderInSlot(endSlot);
        } else if (variant === 'button') {
            return renderInSlot(
                <span className="Tile--arrowIcon">
                    <CgChevronRight />
                </span>
            );
        } else {
            return;
        }
    }

    function truncate(str: string, char: number) {
        return str.length > char ? str.substring(0, char - 3) + '...' : str;
    }

    function renderInnerContent() {
        return (
            <>
                {startSlot && <span className="Tile--slot">{startSlot}</span>}
                <span className="Tile--slot Tile--content">
                    <Title tag="span" size="s" noMargin>
                        {title}
                    </Title>
                    {subtitle && (
                        <Text size="s" tag="span">
                            {truncate(subtitle, 60)}
                        </Text>
                    )}
                </span>
                {renderEndSlot()}
            </>
        );
    }

    function renderFullComponent() {
        return link ? (
            <Link to={{ pathname: link }}>
                <div className={className}>{renderInnerContent()}</div>
            </Link>
        ) : (
            <div className={className} onClick={onClick}>
                {renderInnerContent()}
            </div>
        );
    }

    return renderFullComponent();
};

export default Tile;
